import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../core/services';
import { BrandingService } from '../../core/services/branding.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isRegisterDisabled$;

  constructor(
    public brandingService: BrandingService,
    private fb: FormBuilder,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember_me: false
    });
    this.isRegisterDisabled$ = brandingService.isRegisterDisabled$;
  }

  ngOnInit(): void {
    this.brandingService.setBrandingTitle('Login');
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      this.toastr.error(this.translate.instant('form.please_fill_the_form'));
      return;
    }

    this.authService.login(this.loginForm.value).subscribe(() => {
      this.route.queryParams.subscribe(params => {
        if (params && params.renewal_token) {
          this.router.navigate([`user/policies/view/${params.policy_id}`], { queryParams: { renewal_token: params.renewal_token }});
        } else {
          this.router.navigate(['/user/profile']);
        }
      });
    },
    errors => {
      errors.errors.map(message => this.toastr.error(message));
    });
  }
}
