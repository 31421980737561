export const environment = {
  production: true,
  apiEndpoint: 'https://api-staging.getcoveredinsurance.com/v2',
  depositPaymentEndpoint: 'https://api-dev.alignfinancial.com/DepositChoiceREST/api',
  stripePublishableKey: 'pk_test_A8LaVhU8SMRfPXxmCUz5NqUK',

  title: "get-covered",
  logo: "assets/logos/logo_blue@2x.png",
  logo_footer: "./assets/logos/logo_white@2x.png",
  forms:["residential", "commercial"],
  residential_fee: "Get Covered",
  css_name_file: 'getCovered',
  faq: 'Get Covered',
  faq_phone: '800-833-3448',
  faq_email: 'CustomerCare@us.qbe.com',
  contact_email: 'info@getcoveredinsurance.com',
  contact_phone: '917-300-8200',
  give_back_partner: 'QBE',
  term_of_use: "getcoveredinsurance.com",
  show_back_button: false,
  optional_add_ons: 'Optional Add Ons',
  addressesSearch: {
    primaryProvider: 'fastapi',
    fastAPIBase: 'https://addresses-dev.getcoveredinsurance.com',
    fallbackToSmarty: false
  }
};
