<ng-container *ngIf="(brandingService.brandingInfo$ | async) && (brandingService.brandingInfo$ | async).id">
  <app-header id="top-of-app" class="app-header" *ngIf="!notSupportedPage"></app-header>
  <article class="content-container"
    [class.profile-background]="isUserUrl"
    [class.profile-background_freeway]="isUserUrl && customRgPage === 'freeway'">

    <ng-container *ngIf="customRgPage !== 'freeway'">
      <ng-container *unlessBrandingAttr="'hide_houses_img_site_container'">
        <div class="residential-background" *ngIf="showBg">
          <div class="main-personalize__back main-personalize__back_left">
            <app-main-image-left></app-main-image-left>
          </div>
          <div class="main-personalize__back main-personalize__back_right">
            <app-main-image-right></app-main-image-right>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="main-content" [ngClass]="mainContentClass">
      <router-outlet></router-outlet>
    </div>

  </article>
  <ng-container *ngIf="!notSupportedPage && !iframeMode">
    <app-footer *ngIf="customRgPage !== 'freeway'"></app-footer>
    <app-freeway-layout-footer *ngIf="customRgPage === 'freeway'"></app-freeway-layout-footer>
  </ng-container>
</ng-container>
