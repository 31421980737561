import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

interface AppState {
  showMenu: boolean;
  isGaLoaded: boolean;
}

const APP_INITIAL_STATE = {
  showMenu: null,
  isGaLoaded: false,
};


@Injectable({
  providedIn: 'root'
})
export class AppService {
  private store = new BehaviorSubject<AppState>(APP_INITIAL_STATE);
  public showMenu$ = this.store.asObservable().pipe(
    map(v => v.showMenu)
  );
  public isGaLoaded$ = this.store.asObservable().pipe(
    map(v => v.isGaLoaded)
  );

  setShowMenu(showMenu: boolean) {
    this.store.next({
      ...this.store.getValue(),
      showMenu
    });
  }

  setIsGaLoaded(isGaLoaded: boolean) {
    this.store.next({
      ...this.store.getValue(),
      isGaLoaded
    });
  }


}
