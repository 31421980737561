import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PmaService } from '../pma.service';
import { combineLatest, Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter, take } from 'rxjs/operators';
import { BrandingInfo } from '../../core/models/branding.model';
import { BrandingService } from '../../core/services/branding.service';
import { PmaFormService } from '../pma-form.service';
import { setGeneralData } from '../pma-token';

@Component({
  selector: 'pma-upload-coverage-proof',
  templateUrl: './pma-upload-coverage-proof.component.html',
  styleUrls: ['./pma-upload-coverage-proof.component.css']
})
export class PmaUploadCoverageProofComponent implements OnInit, OnDestroy {
  firstForm: FormGroup;
  step = 'proof-coverage';
  loader = true;

  fullAddress = '';
  isMasterPolicy = false;
  communityAdditionalInterestName = '';
  communityControl = new FormControl(null);
  buildingControl = new FormControl(null);

  nonpreferredAccountSelection = false;
  nonpreferredAddressSelection = false;
  pmaSingleHouses = false;
  nonApplicableControl = new FormControl(false);
  subscription = new Subscription();

  state = '';

  token: string = '';

  constructor(private fb: FormBuilder,
              private pmaService: PmaService,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private brandingService: BrandingService,
              private pmaFormService: PmaFormService) {}

  ngOnInit(): void {
    this.firstForm = this.fb.group({
      first_name: ['', [Validators.required, Validators.maxLength(30)]],
      last_name: ['', [Validators.required, Validators.maxLength(30)]],
      middle_name: ['', [Validators.maxLength(25)]],
      email: [{ value: '', disabled: false }, [Validators.required, Validators.email]],
      state: [{ value: '', disabled: false }, [Validators.required]],
      unit: [{ value: '', disabled: true }, [Validators.required]],
      building_address: ['', []],
      building_address_checkbox: ['', []],
      insurable_id: ['', []]
    });

    this.onRouteChanges();
    this.initPMASelectionMode();
  }

  // ngAfterContentInit(): void {
  // const sub = this.route.queryParams.subscribe((queryParams: Params) => {
  //   this.initFormData(queryParams);
  // });
  // this.subscription.add(sub);
  // }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // This method is for PMC redirect data init
  // initFormData(queryParams: Params): void {
  //   this.firstForm.patchValue(queryParams);
  //
  //   if (queryParams.unit) {
  //     /* setTimeout is used to run after child component attaches events to the form's fields */
  //     setTimeout(() => {
  //       this.firstForm.get('unit').setValue(queryParams.unit);
  //
  //       if (this.firstForm.get('address').value !== '' || this.firstForm.get('unit').value !== '') {
  //         this.firstForm.get('unit').enable();
  //       }
  //     }, 0);
  //   }
  // }

  initPMASelectionMode(): void {
    this.brandingService.brandingInfo$.pipe(
      filter(branding => branding !== null),
      take(1)
    ).subscribe((brandingInfo: BrandingInfo) => {
      const { nonpreferredAccountSelection, nonpreferredAddressSelection, pmaSingleHouses } = this.pmaFormService.getFlagsAndSetForm(brandingInfo, this.firstForm);
      this.nonpreferredAccountSelection = nonpreferredAccountSelection;
      this.nonpreferredAddressSelection = nonpreferredAddressSelection;
      this.pmaSingleHouses = pmaSingleHouses;
    });
  }

  onRouteChanges() {
    combineLatest([
      this.route.queryParams,
      this.route.params
    ]).subscribe(([queryParams, params]) => {
      const token = queryParams && queryParams.token ? queryParams.token : params.token;
      this.setToken(token);
    });
  }

  setToken(token: string) {
    this.token = token;
    if (token) {
      this.pmaService.getInsurableByAuthToken(encodeURIComponent(token)).subscribe(res => {
        this.setData(res);
        this.loader = false;
      }, () => {
        this.loader = false;
      });
    } else {
      this.loader = false;
    }
  }

  setData(res) {
    setGeneralData(this.firstForm, this.communityControl, this.nonpreferredAddressSelection, res, this.buildingControl, this.pmaSingleHouses);
    const addressAttributes = res && res.addresses_attributes && res.addresses_attributes[0] ? res.addresses_attributes[0] : {};
    this.fullAddress = addressAttributes.full;
    this.state = addressAttributes.state;
  }

  changeStep(step) {
    this.step = step;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  setAddress(fullAddress) {
    this.fullAddress = fullAddress;
  }

  get getUnitFormName() {
    return this.firstForm.get('unit').value && this.firstForm.get('unit').value.title;
  }

  get unitName() {
    return this.getUnitFormName || this.firstForm.get('unit').value;
  }

  get communityName() {
    return this.communityControl && this.communityControl.value && this.communityControl.value.title;
  }

}
