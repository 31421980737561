import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { InitService } from './core/services/init.service';
import { AuthService } from './core/services/auth.service';
import { HttpTokenInterceptor } from './core/interceptors/http.token.interceptor';
import { HttpErrorInterceptor } from './core/interceptors/httperror.interceptor';
import { RedirectComponent } from './redirect.component';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import {StaticPageComponent} from './static-page/static-page.component';

registerLocaleData(localeEn, 'en');

declare global {
  interface Window {
    analytics: any;
    ga: any;
    gtag: any;
    FS: any;
    Intercom: any;
    intercom_app_id: any;
  }
}


@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    StaticPageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SharedModule,
    AuthModule,
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthService,
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: (is: InitService) => function () {
        return is.load()
      },
      deps: [InitService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
